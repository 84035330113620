// stores/userStore.js
import { defineStore } from "pinia";
import axios from "axios";
import { useUserStore } from "./userStore";
import { useToastStore } from "./toastStore";
import { watch, ref } from 'vue';

const apiInstance = axios.create();

apiInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error?.response?.status === 401) {
      const userStore = useUserStore();
      userStore.logout();
      window.location.href = "/login";
    }
    return Promise.reject(error);
  }
);



function hexToRGB(hex) {
  let r = parseInt(hex.slice(1, 3), 16),
    g = parseInt(hex.slice(3, 5), 16),
    b = parseInt(hex.slice(5, 7), 16);
  return `${r}, ${g}, ${b}`;
}

export const useOrganizationStore = defineStore("organization", {
  state: () => ({
    organization: { exists: false, ai_enabled: true },
    organizationInfoLoading: false,
    features: {
      courses: 0,
      archivable: false,
      branding: false,
      uploaders: 0,
      attachments: false,
    },
    subdomain: ''
  }),
  getters: {},
  actions: {
    async getOrganizationInfo() {
      this.organizationInfoLoading = true;
      
      // Get the user store to access the user ID
      const userStore = useUserStore();
      
      // Make the request with the user ID if available
      let response;
      if (userStore.user && userStore.user._id) {
        // Add user ID as a query parameter
        response = await apiInstance.get(`/api/organization/info?userId=${userStore.user._id}`);
      } else {
        response = await apiInstance.get("/api/organization/info");
      }
      
      // Preserve the existing ai_enabled value if it exists
      const currentAiEnabled = this.organization.ai_enabled;
      this.organization = response.data;
      
      // If ai_enabled is not in the response but we had a value before, keep it
      if (this.organization.ai_enabled === undefined) {
        this.organization.ai_enabled = currentAiEnabled !== undefined ? currentAiEnabled : true;
      }
      this.organization.exists = true;
      document.documentElement.style.setProperty(
        "--font-color",
        this.organization.font_color
      );
      document.documentElement.style.setProperty(
        "--base-color",
        this.organization.base_color
      );
      //convert colors to rgba for better contrast
      let baseColor = this.organization.base_color;
      let fontColor = this.organization.font_color;
      let baseColorRGB = hexToRGB(baseColor);
      let fontColorRGB = hexToRGB(fontColor);
      document.documentElement.style.setProperty(
        "--base-color-rgb",
        baseColorRGB
      );
      document.documentElement.style.setProperty(
        "--font-color-rgb",
        fontColorRGB
      );
      console.log("Organization info loaded with AI enabled:", this.organization.ai_enabled);
      this.organizationInfoLoading = false
    },
    async getOrganizationFeatures() {
      if (this.organization.owner) {
        const response = await apiInstance.get(
          `/api/organization/features/${this.organization.owner._id}`
        );
        this.features = response.data;
      } else {
        this.features = {
          courses: 0,
          archivable: false,
          branding: false,
          uploaders: 0,
          attachments: false,
        };
      }
    },
    //add/remove course from portfolio
    async updatePortfolioCourses(courseId) {
      const response = await apiInstance.put(
        `/api/organization/portfolio/${courseId}`
      );
      this.organization = response.data;
    },
    //update portfolio info
    async updatePortfolioInfo() {
      const toastStore = useToastStore()
      toastStore.showPortfolioSaveToast = true
      const response = await apiInstance.put("/api/organization/portfolio", {
        about: this.organization.about,
        contact_email: this.organization.contact_email,
        home_page: this.organization.home_page,
      });
      this.organization = response.data;
      setTimeout(() => {
        toastStore.showPortfolioSaveToast = false
      }, 2000)
    },
    
    // Update AI enabled setting
    async updateAiEnabled(enabled) {
      console.log("Updating AI enabled setting to:", enabled);
      
      // Use Vue reactivity to make sure components update
      // First set to undefined (forces reactivity)
      this.organization.ai_enabled = undefined;
      // Then set to the new value
      this.organization.ai_enabled = enabled;
      
      // Get the user store to access the user ID
      const userStore = useUserStore();
      
      // Immediately save the setting to the server
      try {
        const response = await apiInstance.put("/api/organization/update_ai_setting", {
          ai_enabled: enabled,
          organizationId: this.organization._id,
          userId: userStore.user?._id
        });
        console.log("AI setting updated on server:", response.data);
        
        // Force getOrganizationInfo refresh to update all components
        // with a short delay to make sure the server has processed the update
        setTimeout(async () => {
          await this.getOrganizationInfo();
        }, 500);
        
        return response.data;
      } catch (error) {
        console.error("Error updating AI setting:", error);
        throw error;
      }
    },
  },
  persist: true,
});